@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {
    p {
        line-height: 1.5 !important;
    }

}


@layer utilities {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-weight: 400;
        text-rendering: optimizeLegibility;
    }

}



@layer utilities {
    .text-shadow {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }

}


@layer utilities {
    .sunken-text {
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    }

}



@layer utilities {
    .navbar_item {
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-bottom: 15px;

    }

}

@layer utilities {
    .navbar-hover:hover {
        cursor: pointer;
    }

}

@layer utilities {
    .casebar_item {
        display: flex;
        gap: 5px;
        margin-right: 10px;
        margin-top: 15px;
    }
}



@layer utilities {
    .symbol {
        font-size: 30px;
    }
}

